<!-- header Start-->
<section class="event header" id="home">
    <div class="header3 bg" [ngStyle]="{'background-image': 'url(assets/images/event/event_slider.jpg)'}" style="min-height: 100vh;">
        <div class="event-content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3 col-md-10 offset-md-1">
                        <div class="center-text">
                            <div class="text-center w-100">
                                <div class="h1-margin" [ngStyle]="{'background-image': 'url(assets/images/event/title.png)'}" style="
                                        background-position: center center;
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        width: auto;
                                        height: 80px;">
                                    <!-- <h1 class="header-title" style="font-family: BrandOtf;">#<span style="font-size: larger;">BRILLIANT AWARDS</span></h1>
                                    <h3 style="color: white;">The Brilliant Concert 2023</h3> -->
                                </div>
                                <div class="wedding-time">
                                    <div class="row">
                                        <div class="col-sm-3 col-6">
                                            <div class="countdown-container">
                                                <div class="cd-wrapper">
                                                    <div class="cd-body">
                                                        <h2 class="text-white">
                                                            <span id="days" class="days-text" style="font-size: 75px !important;">{{day}}</span>
                                                        </h2>
                                                    </div>
                                                    <div class="cd-footer">
                                                        <span class="days text-white">DAYS</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 col-6">
                                            <div class="countdown-container">
                                                <div class="cd-wrapper">
                                                    <div class="cd-body">
                                                        <h2 class="text-white">
                                                            <span id="days" class="days-text" style="font-size: 75px !important;">{{hour}}</span>
                                                        </h2>
                                                    </div>
                                                    <div class="cd-footer">
                                                        <span class="days text-white">HOURS</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 col-6">
                                            <div class="countdown-container">
                                                <div class="cd-wrapper">
                                                    <div class="cd-body">
                                                        <h2 class="text-white">
                                                            <span id="days" class="days-text" style="font-size: 75px !important;">{{min}}</span>
                                                        </h2>
                                                    </div>
                                                    <div class="cd-footer">
                                                        <span class="days text-white">MINUTES</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 col-6">
                                            <div class="countdown-container">
                                                <div class="cd-wrapper">
                                                    <div class="cd-body">
                                                        <h2 class="text-white">
                                                            <span id="days" class="days-text" style="font-size: 75px !important;">{{sec}}</span>
                                                        </h2>
                                                    </div>
                                                    <div class="cd-footer">
                                                        <span class="days text-white">SECONDS</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 class="text-grey zexon">
                                    <i class="fa fa-map-marker m-r-10" aria-hidden="true"></i> 
                                    Newport Mall
                                </h4>
                                <h5 class="header-sub-text text-grey">
                                    <i class="fa fa-calendar m-r-10" aria-hidden="true"></i>21 December 2023
                                </h5>
                                <!-- <div class="text-center link-horizontal">
                                    <ul>
                                        <li>
                                            <a class=" btn btn-default back-white text-uppercase border-not" href="#">view event details</a>
                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="set-bottom set-abs">
            <a class="center-content down" (click)="onRedirect($event)">
                <img src="assets/images/event/image-down.png" alt="" class="scroll-down">
            </a>
        </div>
    </div>
</section>
<!-- header end-->