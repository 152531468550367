<!--blog section start-->
<section class="event blog set-relative">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 style="font-family: BrandOtf;"><span>our </span>prizes</h2>
                    </div>
                    <div class="sub-title">
                        <P style="color: #4b4b4b;">Mark your calendars and be ready to be swept away by the excitement as we unveil <span>BIG</span> surprises and extraordinary <span>PRIZES</span> that will leave you in awe!</P>
                    </div>
                </div>
            </div>
            <div class="col-12">
                    <owl-carousel-o [options]="blogCarouselOptions">
                            <ng-container *ngFor="let blog of blogs">
                                <ng-template carouselSlide class="item">
                        <div class="img-container">
                            <img [src]="blog.img" alt="" class="img-fluid">
                            <!-- <div class="overlay"></div> -->
                            <div class="blog-info set-abs bottom-0">
                                <!-- <div class="center-text flex m-b-20">
                                    <i class="fa fa-user-o m-r-5" aria-hidden="true"></i>
                                    <h6 class="m-r-25 font-blog">{{blog.type}}</h6>
                                    <i class="fa fa-calendar-o m-r-5 " aria-hidden="true"></i>
                                    <h6 class="font-blog">{{blog.date}}</h6>
                                </div> -->
                                <!-- <h4 class="blog-text">{{blog.title}}</h4>
                                <p class="borders-before"><span>{{blog.subTitle}}</span></p>
                                <p class="small-font">{{blog.description}}</p> -->
                                <div class="social-list">
                                    <!-- <ul>
                                        <li>
                                            <a href="#">
                                                <div class="center-content">
                                                    <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div class="center-content">
                                                    <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div class="center-content">
                                                    <i class="fa fa-google center-content" aria-hidden="true"></i>
                                                </div>
                                            </a>
                                        </li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                        </ng-template>
                        </ng-container>
                        </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!--blog section end-->
