
<!--copyright section start-->
<div class="event copyright flex">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div class="link-horizontal d-flex align-content-lg-center h-100">
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/BrilliantSkinEssentialsMain" class="center-content">
                                <i class="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/brilliantskinph" class="center-content">
                                <i class="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/brilliantskinessentialsmain" class="center-content">
                                <i class="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/c/BRILLIANTSKINESSENTIALS" class="center-content">
                                <i class="fa fa-youtube" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <p class="copyright-text text-right text-white">Brilliant Awards © 2023. All rights reserved. <span>Made possible by <a href="http://bytescrafter.net" style="color: white;">BytesCrafter</a></span> </p>
            </div>
        </div>
    </div>
</div>
<!--copyright section end-->
<app-tap-to-top></app-tap-to-top>
