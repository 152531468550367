<!--sponsor section start-->
<section class="event sponsor set-relative bg bg-img2 bg-about" [ngStyle]="{'background-image': 'url(assets/images/event/h3-bg.jpg)'}">
    <div class="container set-margin">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 class=" text-white" style="font-family: BrandOtf;">Our Collaborations</h2>
                    </div>
                    <div class="sub-title">
                        <P class="text-white">The umbrella brands of Brilliant Skin Essentials, Inc. are in collaboration with other leading and successful brands and personalities, which are also dominant in the online market and beauty industry.</P>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-6 sponsor-img" *ngFor="let data of sponsor">
                <div class="text-center">
                    <a href="#"><img [src]="data.img" alt="" class="img-fluid"></a>
                </div>
            </div>
            <div class="col-md-4 col-12 sponsor-img" *ngFor="let data of sponsor2">
                <div class="text-center">
                    <a href="#"><img [src]="data.img" alt="" class="img-fluid"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--sponsor section end-->
