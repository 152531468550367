import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.scss']
})
export class EventHeaderComponent implements OnInit, OnDestroy {
  
  public bookingOffset: any = null;

  end: any = new Date('12/21/2023 06:00 PM');

  public day: any = 0;
  public hour: any = 0;
  public min: any = 0;
  public sec: any = 0;

  _second: any;
  _minute: any;
  _hour: any;
  _day: any;
  timer;

  constructor() {}

  showRemaining() {
    this._second = 1000;
    this._minute = this._second * 60;
    this._hour = this._minute * 60;
    this._day = this._hour * 24;

    let now: any = new Date();
    let distance = this.end - now;
    if (distance < 0) {
        //clearInterval(this.timer);
        //document.getElementById('countdown').innerHTML = 'EXPIRED!';
        return;
    }
    var days = Math.floor(distance / this._day);
    var hours = Math.floor((distance % this._day) / this._hour);
    var minutes = Math.floor((distance % this._hour) / this._minute);
    var seconds = Math.floor((distance % this._minute) / this._second);

    this.day = days;
    this.hour = hours;
    this.min = minutes;
    this.sec = seconds;
}

  onRedirect(e) {
    e.preventDefault();
    let el = document.getElementById('register');
    el.scrollIntoView({behavior: 'smooth'});
  }

  ngOnInit() {
    setInterval(() => {
      this.showRemaining();
    }, 1000)
  }

  ngOnDestroy() {
  }
  
}
