import { Component } from '@angular/core';
import packageJson from '../../package.json';
import { ApisService } from './shared/service/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BSEI | Pinakinang 2023';

  public version: string = packageJson.version;

  constructor(
    public api: ApisService
  ) {
    console.log('Radiant Event App v'+this.version+' © 2023. All rights reserved. Made possible by BytesCarfter.');
  }
}
