import { Component, ContentChild, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApisService } from '../../../shared/service/api.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  @ViewChild('content') content: ElementRef;

  first_name: any = '';
  last_name: any = '';
  email: any = '';
  phone: any = '';
  address: any = '';
  refid: any = '';

  message: any = '';
  greeting: any = '';
  notif: any = '';

  public age: any = 'none';
  public ageList: any[] = [];

  constructor(
    private api: ApisService,
    private config: NgbModalConfig,
    private modal: NgbModal,
    private route: ActivatedRoute
    //private loader: LoaderComponent
  ) { 
    config.backdrop = 'static';
		config.keyboard = false;

    for(var i=12; i<=70; i++) {
      this.ageList.push(i);
    }
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if(params.refid) {
          this.refid = params.refid;
        }
      }
    );
  }

  setAge(item) {
    this.age = item;
  }

  showGreeting(message: any) {
    this.notif = message;
    this.modal.open(this.content, {centered: true});
  }

  showWarning(message: any) {
    this.notif = message;
    this.modal.open(this.content, {centered: true});
  }

  reserveSeat(content) {
    //this.loader.show = true;

    if (!this.first_name || !this.last_name || !this.email || !this.phone || !this.refid || !this.age || this.age == 'none' ) {
      this.showWarning('All non-optional fields are required');
      return false;
    }

    const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailfilter.test(this.email)) {
      this.showWarning('Please enter valid email, thank you!');
      return false;
    }

    //Prepare user object.
    let user = {
      event_id: 103,
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      phone: this.phone,
      refid: this.refid,
      seat: '1',
      age: this.age,
      address: this.address,
      group: 'viewer',
    };

    //submit to server
    this.api.posts('/v1/api/EventPass/reserve', user).then((res: any) => {
      if(res && res.success === true && res.data) {
        if(!res.data.existing) {
          this.showGreeting( 'Your ticket pass reservation is now being process. Ref# '+res.data.uuid+'. Please check your email for more info about your seat reservation.');
        } else {
          this.showGreeting( 'Your ticket pass is already being process. Ref# '+res.data.uuid+'. Please check your email for more info about your seat reservation.');
        }
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.phone = '';
        this.address = '';
        this.refid = '';
        this.age = 'none';
      } else {
        this.showWarning(res.message);
      }

    }).catch(error => {
      this.showWarning('Something went wrong, contact technical support.');
      console.log('error', error);
    });
  }
}
