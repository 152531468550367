<!--contact section start-->
<footer class="event contact set-relative bg bg-img bg-about p-b-0" id="contact" [ngStyle]="{'background-image': 'url(assets/images/event/bg1.jpg)'}">
    <div class="container p-b-150">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 class=" text-white" style="font-family: BrandOtf;">Event Location</h2>
                    </div>
                </div>
            </div>
            <div class="col-md-12 p-r-0 map">
                <div class="iframe-container">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3862.3960873239766!2d121.0167687!3d14.519322!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397cecdb24090a1%3A0xc6c0d38bf41ec8c1!2sNewport%20Mall!5e0!3m2!1sen!2sph!4v1702363347474!5m2!1sen!2sph" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>"
                </div>
            </div>
            <!-- <div class="col-xl-5 p-l-0 col-md-6 set-z-index form-footer">
                <div class="bg-white">

                    <form>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="name">Name *</label>
                                    <input type="text" class="form-control" id="name">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Phone">Phone *</label>
                                    <input type="text" class="form-control" id="Phone">
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="Email">Email *</label>
                                    <input type="text" class="form-control" id="Email">
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="Subject">Subject *</label>
                                    <input type="text" class="form-control" id="Subject">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Message">Message *</label>
                            <input type="text" class="form-control" id="Message">
                        </div>
                        <a class="btn btn-default primary-btn m-0-auto">send</a>
                    </form>
                </div>
            </div> -->
        </div>
    </div>
</footer>
<!--contact section end-->
