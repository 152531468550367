import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './components/loader/loader.component';
import { MenuComponent } from './components/navigation/menu/menu.component';
import { BreadcrumbComponent } from './components/pages/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/pages/footer/footer.component';
import { HeaderComponent } from './components/pages/header/header.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';

@NgModule({
  declarations: [MenuComponent, TapToTopComponent, HeaderComponent, BreadcrumbComponent, FooterComponent, LoaderComponent],
  exports: [
    MenuComponent,
    LoaderComponent,
    TapToTopComponent,
    HeaderComponent,
    BreadcrumbComponent,
    FooterComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class SharedModule { }
