<div class="loader-wrapper" style="opacity: 25%;" *ngIf="api.isLoading">
    <div class="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
  </div>
  
<app-loader></app-loader>
<router-outlet></router-outlet>