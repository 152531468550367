<!--schedule section start-->
<section class="event schedule set-relative" id="schedule">
    <div class="circle">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2><span>our</span> schedule</h2>
                    </div>
                    <div class="sub-title">
                        <P>Register now and reserve your seat for this <span>year's unice,</span> the
                            largest <span>web development</span> and online marketing <span>conference in UK,</span>
                            covering everything you need to know in order to develop a successful online business.</P>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="cal-time">
                    <div class="time">
                        <h4 class="text-capitalize monday-text">Monday, September 30, 2018</h4>
                    </div>
                </div>
                <div class="event-container scrollbar">
                    <div>
                        <div class="cal-time events" *ngFor="let data of schedule">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="schedule-event">
                                        <h5 class="names">{{data.name}}</h5>
                                        <img [src]="data.icon" alt="" class="img-fluid schedule-img">
                                        <p class="schedule-description">{{data.description}}</p>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="timing">
                                        <h5 class="event-time">{{data.totime}}</h5>
                                        <div class="border-container">
                                            <div class="border-dashed"></div>
                                        </div>
                                        <h5 class="event-time">{{data.endtime}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--schedule section end-->
