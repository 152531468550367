<!-- about section Start-->
<section class="event about bg bg-img bg-about" [ngStyle]="{'background-image': 'url(assets/images/event/bg1.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 class=" text-white" style="font-family: BrandOtf;">About the Event</h2>
                    </div>
                </div>
        </div>
            <div class="col-lg-4 offset-lg-1 col-md-6">
                <div class="text-right">
                    <div class="announcer-img">
                        <img src="assets/images/event/1.jpg" alt="" class="img-fluid" style="display: none;">
                        <img src="assets/images/event/1.jpg" alt="" class="img-fluid" style="display: none;">
                        <img src="assets/images/event/about.png" alt="" class="img-fluid" aTilt data-tilt data-tilt-max="3"
                             data-tilt-speed="400" data-tilt-perspective="500">
                    </div>
                </div>
            </div>
            <div class="col-xl-5 offset-xl-1 col-lg-7 col-md-6">
                <div class="abouts center-text">
                    <div class="format">
                        <div class="format-head-text" style="margin-bottom: 10px;">
                            <h4 class="about-font-header text-uppercase text-white">Brilliant Awards 2023</h4>
                        </div>
                        <div class="format-small-text" style="margin-bottom: 40px;">
                            <h6 class="text-white borders-before"><span></span>#BrilliantAwards #TheBrightestOfAllTime</h6>
                        </div>
                        <div class="format-sub-text">
                            <p class="about-para p-light">In a world where beauty meets authenticity, true radiance shines from genuine commitment to expressing your best self.</p>
                            <p class="about-para p-light">And to be our best selves, we are supported by the brilliance of our F&D's who helped elevate our beauty to new heights through Brilliant Skin Essentials. </p>
                            <p class="about-para p-light">Each one of them deserves a spotlight. 
                                Each one of them deserves to shine.</p>
                            <p class="about-para p-light">Each one of the deserves to be called Brightest off all time.</p>
                        </div>
                        <!-- <a class="btn btn-default " href="#">read more</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->
