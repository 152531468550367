import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanionComponent } from './companion/companion.component';
import { EventsComponent } from './events/events.component';

const routes: Routes = [
  {
    path: '',
    component: EventsComponent,
    data: {
      title: "BSEI Event | BRILLIANT AWARDS 2023"
    }
  },
  {
    path: 'companion',
    component: CompanionComponent,
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
