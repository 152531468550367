import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'swiper/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountToModule } from 'angular-count-to';
import { NgxMasonryModule } from 'ngx-masonry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgxPayPalModule } from 'ngx-paypal';
import { Ng5SliderModule } from 'ng5-slider';
import { SharedModule } from '../shared/shared.module'
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { EventAboutComponent } from './events/event-about/event-about.component';
import { EventBlogComponent } from './events/event-blog/event-blog.component';
import { EventBookingComponent } from './events/event-booking/event-booking.component';
import { EventContactComponent } from './events/event-contact/event-contact.component';
import { EventCopyrightComponent } from './events/event-copyright/event-copyright.component';
import { EventCounterComponent } from './events/event-counter/event-counter.component';
import { EventGalleryComponent } from './events/event-gallery/event-gallery.component';
import { EventHeaderComponent } from './events/event-header/event-header.component';
import { EventNavComponent } from './events/event-nav/event-nav.component';
import { EventPricingComponent } from './events/event-pricing/event-pricing.component';
import { EventScheduleComponent } from './events/event-schedule/event-schedule.component';
import { EventSpeakerComponent } from './events/event-speaker/event-speaker.component';
import { EventSponsorComponent } from './events/event-sponsor/event-sponsor.component';
import { EventSubscribeComponent } from './events/event-subscribe/event-subscribe.component';
import { EventTestimonialComponent } from './events/event-testimonial/event-testimonial.component';
//Event Layout
import { EventsComponent } from './events/events.component';
import { CompanionComponent } from './companion/companion.component';
import { SignupComponent } from './companion/signup/signup.component';

@NgModule({
  declarations: [
    EventsComponent, EventNavComponent, EventHeaderComponent, EventBookingComponent, EventAboutComponent, EventSpeakerComponent, EventSponsorComponent, EventScheduleComponent, EventTestimonialComponent, EventGalleryComponent, EventCounterComponent, EventSubscribeComponent, EventPricingComponent, EventBlogComponent, EventContactComponent, EventCopyrightComponent, CompanionComponent, SignupComponent],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    CarouselModule,
    NgbModule,
    GalleryModule,
    LightboxModule,
    SharedModule,
    CountToModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    NgxMasonryModule
  ],
  exports: [],
  providers: []
})

export class LayoutsModule { }
