import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-companion',
  templateUrl: './companion.component.html',
  styleUrls: ['./companion.component.scss']
})
export class CompanionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
