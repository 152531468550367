<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Notification</h4>
		<!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
	</div>
	<div class="modal-body">
		<p style="text-align: center; padding: 20px 0; color: #3f3f3f;">{{notif}}</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
	</div>
</ng-template>

<!-- booking Start-->
<section class="event booking set-relative" id="register">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 style="font-family: BrandOtf;">Book Your <span>FREE</span> Ticket</h2>
                    </div>
                    <div class="sub-title">
                        <P style="color: #4b4b4b;">Register now and reserve your seat for this year's<span> BSEI Event - #BRILLIANT AWARDS,</span> the
                            largest <span>Brilliant Event </span> <span> in the history,</span>
                            covering everything you need to know about the brand.</P>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-inline">
                    <div class="col-sm-4 m-b-50">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="first_name" placeholder="first name">
                        </div>
                    </div>
                    <div class="col-sm-4 m-b-50">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="last_name" placeholder="last name">
                        </div>
                    </div>
                    <div class="col-sm-4 m-b-50">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="email" placeholder="email">
                        </div>
                    </div>
                    <div class="col-sm-3 m-b-50">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="phone" placeholder="phone">
                        </div>
                    </div>
                    <div class="col-sm-6 m-b-50">
                        <div class="form-group">
                            <input [(ngModel)]="refid" type="text" placeholder="required! reference id, check email. auto-filled." disabled>
                        </div>
                    </div>
                    <div class="col-sm-2 m-b-50">
                        <div class="form-group">
                            <div ngbDropdown class="d-inline-block">
                                <button type="button" class="btn btn-light" id="dropdownGroup" ngbDropdownToggle>
                                    Current Age: {{age}}
                                </button>
                                <div ngbDropdownMenu aria-labelledby="dropdownGroup">
                                    <button ngbDropdownItem (click)="setAge(item)" *ngFor="let item of ageList;">{{item}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 m-b-40">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="address" placeholder="optional! address">
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <ngb-alert type="info" [dismissible]="false" *ngIf="greeting" class="m-b-30">
                        <strong>Congrats!</strong> {{greeting}}
                    </ngb-alert>
                    <ngb-alert type="warning" [dismissible]="false" *ngIf="message" class="m-b-30">
                        <strong>Warning!</strong> {{message}}
                    </ngb-alert>
                </div>
                <div class="text-center m-b-50">
                    <P style="color: #e80e0e;"><strong>For Guest companion only!</strong> It is required to click the link from the email we recently send. This form will not be accepted if the reference id is not set. You can always register for an epass here using this link <a href="https://events.brilliantskinessentials.ph/#register">here</a>.</P>
                </div>
                <div class="text-center">
                    <a class="btn btn-default primary-btn m-0-auto" (click)="reserveSeat(content)">Reserve Seats</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- booking end-->
