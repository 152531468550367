<!-- <app-event-nav></app-event-nav> -->
<app-event-header></app-event-header>

<app-event-speaker></app-event-speaker>
<app-event-about></app-event-about>
<app-event-blog></app-event-blog>
<app-event-sponsor></app-event-sponsor>
<app-event-booking></app-event-booking>

<!-- <app-event-pricing></app-event-pricing> -->
<!-- <app-event-counter></app-event-counter> -->
<!-- <app-event-schedule></app-event-schedule>
<app-event-testimonial></app-event-testimonial>
<app-event-gallery></app-event-gallery> -->
<!-- <app-event-subscribe></app-event-subscribe> -->

<app-event-contact></app-event-contact>
<app-event-copyright></app-event-copyright>