<!--subscribe section start-->
<section class="event subscribe subscribe-3 set-relative">
    <div class="circle">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2><span>subs</span>cribe</h2>
                    </div>
                    <div class="sub-title">
                        <P style="color: #4b4b4b;">Fusce ornare vulputate varius. Sed ac hendrerit velit. Nullam vel eros eu massa aliquam accumsan. Donec vitae dui volutpat libero aliquet pellentesque at tincidunt nunc. Nulla facilisi. Proin eget pharetra urna, a ullamcorper neque.</P>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="subscribe">
                    <div class="center-content">
                        <div class="form-group">
                            <div class="flex">
                                <input type="email" class="form-control text-capitalize"
                                       placeholder="Please Enter Your Email Address"
                                       name="email">
                                <div class="button-primary">
                                    <button type="submit"
                                            class=" btn btn-default primary-btn text-white text-capitalize">
                                        contact us
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--subscribe section end-->
